<template>

  
  
<b-modal
        ref="modalEliminar"
        centered
        hide-footer
        size="sm"
        title="Eliminar negocio"
        @hidden="closeModalEliminar"
      >
        <div class="d-block text-center">
  
          <b-form
            autocomplete="off"
            @submit.prevent="onSubmitDeleteNegocio"
          >
  
            <b-row>
  
              <b-col
                      sm="12"
                      md="12"
                      lg="12"
                      xl="12"
                      style="margin-bottom: 10px;"
                    >


                    <p class="textLabel" style="text-align: left;margin-top: 0px;margin-bottom: 5px;">
                        Motivo de la eliminación del negocio (*):</p>

                        <b-form-textarea
                          v-model="motivoEliminacion"
                          placeholder="Especifique el motivo de la eliminación del negocio"
                          rows="5"
                          required
                          no-resize
                          :readonly="isActiveEliminacion"
                          :state="motivoEliminacion.length <= maxChar"
                          class="char-textarea"
                          :class="motivoEliminacion.length >= maxChar ? 'text-danger' : ''"
                        />
                        <small
                          class="textarea-counter-value float-left"
                          :class="motivoEliminacion.length >= maxChar ? 'bg-danger' : ''"
                        >
                          <span class="char-count">{{ motivoEliminacion.length }}</span> / {{ maxChar }}
                        </small>

              

                    

                    </b-col>
  
              <b-col
                sm="12"
                md="12"
                lg="12"
                xl="12"
              >
  
                <p
                  class="textLabel"
                  style="text-align: center;margin-bottom: 10px;"
                >
                  Ingrese su código PIN (*): <feather-icon
                                    icon="HelpCircleIcon"
                                    size="16"
                                      v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                    title="Código PIN de seguridad"
                                    style="margin-right: 5px;cursor:pointer;"
                                    /> </p>
  
                <PincodeInput
                  v-model="pinEliminacion"
                  placeholder="-"
                  :length="6"
                  :autofocus="false"
                  :secure="true"
                  :disabled="isActiveEliminacion"
                  required
                />
  
              </b-col>
  
              <b-col
                sm="12"
                md="12"
                lg="12"
                xl="12"
              >
  
                <b-button
                  id="btn_eliminacion"
                  ref="btn_eliminacion"
                  type="submit"
                  variant="primary"
                  :disabled="isActiveEliminacion"
                  block
                >
  
                  Eliminar negocio
  
                </b-button>
  
              </b-col>
  
            </b-row>
          </b-form>
  
        </div>
  
      </b-modal>
          
          </template>
          
          <script>
          import {
            BButton, BRow, VBPopover, BCol, BCard,BFormTextarea, BForm
          } from 'bootstrap-vue'
          
          import PincodeInput from 'vue-pincode-input'
          
          export default {
            components: {
                PincodeInput,
                BFormTextarea,
                BForm,
              BButton,
              BRow,
              BCol,
              BCard,
      
            },
            directives: {
              'b-popover': VBPopover,
            },
            props: ['idLocal', 'tokenAuth', 'userId'],
            data() {
          
          
              return {

                isActiveEliminacion:false,
                 maxChar: 150,
                motivoEliminacion:"",
                pinEliminacion:"",
              }
            },
            computed: {
          
            },
            watch: {
          
            },
            mounted() {

                this.$swal({
                          title: '¿Seguro que deseas eliminar este negocio?',
                          text: `Una vez que elimines el negocio, no podrás volverlo a recuperar y perderás todo lo configurado como la verificación, links masivos etc.`,
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonText: 'Si, eliminar',
                          cancelButtonText: 'No',
                          customClass: {
                            confirmButton: 'btn btn-primary',
                            cancelButton: 'btn btn-outline-secondary ml-1',
                          },
                          buttonsStyling: false,
                        }).then(result => {
                          if (result.value) {
                            this.motivoEliminacion= this.motivoEliminacion;
                              this.pinEliminacion="";
                              this.$refs.modalEliminar.show()
                           
                          }else{
                            this.closeModalEliminar();
                          }

                        })
       
       
            },
            methods: {
    
                  
    closeModalEliminar(){
                    this.$eventBus.$emit('reiniciarModalEliminar')
                },

 onSubmitDeleteNegocio(event){
        event.preventDefault();

        
      if(Number(this.motivoEliminacion.length) > Number(this.maxChar)){

                  
              this.$toast.error("El texto del motivo de eliminación del negocio sobrepasa el límite permitido de "+this.maxChar+" caracteres", {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              })

              }else{



              this.isActiveEliminacion=true;

              const local_json = {
              userId: this.userId, motivo: this.motivoEliminacion, pin: this.pinEliminacion, id: this.idLocal
              }





              const local_string = JSON.stringify(local_json)

              const dataEncripted = this.$encryptBackoffice.encrypt(local_string)

              document.getElementById('btn_eliminacion').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Eliminando'


              this.$https.post('/locals/deleteNegocio/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
              document.getElementById('btn_eliminacion').innerHTML = 'Eliminar negocio';
              this.isActiveEliminacion=false;


              if (response.data.code == 200) {




              this.$toast.success(response.data.message, {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
              });

              this.$eventBus.$emit('activeHome');
              this.$eventBus.$emit('reiniciarAllNegocio');


              } else {

              this.$toast.error(response.data.message, {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
              })

              if (response.data.code == 401) {



              localStorage.removeItem('userData')


              this.$router.push({ name: 'auth-login' })
              } 
              }
              }).catch(error => {
              this.$toast.error("Ocurrio un error " + error, {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
              })
              })

              }

      },
      
              
             
          
            },
          }
          </script>
          
          <style lang="scss">
          
          
          
          </style>
          